import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/en-gb"; // Import British English locale for DD/MM/YYYY format
import styles from "./DatePicker.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { noop } from "../../../utils/noop";

const cx = createModuleStyleExtractor(styles);

// Define a custom mapping for minimal weekday format
const minimalWeekdays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

const CustomDatePicker = ({
  label = "",
  value,
  error = false,
  onChange = noop,
  onBlur = noop,
  centeredText = false,
  maxDate = "",
  disableTextInput = false,
}) => {
  // Set locale to 'en-gb' for DD/MM/YYYY format globally in moment
  useEffect(() => {
    moment.locale("en-gb");
  }, []);
  return (
    <div className={cx("a-mui-datepicker-container")}>
      <div className={`field-group${error ? " Mui-error" : ""}`}>
        {label && <label>{label}</label>}
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
          <DatePicker
            className="a-mui-datepicker"
            inputFormat="DD/MM/YYYY"
            value={value ? moment(value, "DD/MM/YYYY") : null}
            onChange={(newValue) => {
              const formattedValue = newValue
                ? newValue.format("DD/MM/YYYY")
                : null;
              onChange(formattedValue);
            }}
            dayOfWeekFormatter={(date) => minimalWeekdays[date.weekday()]}
            desktopModeMediaQuery="@media (min-width:0px)"
            reduceAnimations
            maxDate={maxDate}
            slotProps={{
              popper: {
                placement: "bottom",
                sx: {
                  marginTop: "10px !important",
                },
              },
              desktopPaper: {
                sx: {
                  borderRadius: "10px !important",
                },
              },
              textField: {
                sx: centeredText
                  ? {
                      "& .MuiInputBase-input": {
                        textAlign: "center", // Styling for internal input element
                        paddingLeft: "60px !important", // Additional padding for the input element => for Ambassador and Prospect signin form
                      },
                    }
                  : {},
                inputProps: {
                  ...(disableTextInput ? { disabled: true } : {}), // disables text input, user can add the date with dateInputCalendar
                },
              },
            }}
            renderInput={(params) => (
              <TextField {...params} onBlur={onBlur} error={!!error} />
            )}
          />
        </LocalizationProvider>
        {error && <span className="p-error">{error}</span>}
      </div>
    </div>
  );
};

export default CustomDatePicker;
