import React, { memo, useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Header.module.scss";
import { ROUTES } from "../../../routes";
import { MenuItem, MenuItemModifier } from "../../atoms/MenuItem/MenuItem";
import {
  SubMenuItem,
  SubMenuItemModifier,
} from "../../atoms/SubMenuItem/SubMenuItem";
import HeaderLogos from "../../atoms/HeaderLogos/HeaderLogos";
import ProspectHeaderLogos from "../../atoms/ProspectHeaderLogos/ProspectHeaderLogos";
import HelpCenterIcon from "../../atoms/HelpCenterIcon/HelpCenterIcon";
import NotificationIcon from "../../atoms/NotificationIcon/NotificationIcon";
import {
  UserProfileIcon,
  UserProfileIconModifier,
} from "../../atoms/UserProfileIcon/UserProfileIcon";
import useClickOutside from "../../../hooks/useClickOutside";
import { setSelectedSchool } from "../../../redux/reducers/userReducer";
import useClickOutsideV2 from "../../../hooks/useClickOutsideV2";
import NotificationsDrawer from "../NotificationsDrawer/NotificationsDrawer";
import { disableBodyScroll } from "../../../utils/common";
import { Mixpanel } from "../../../MixPanel/mixpanel";
import MessagesIcon from "../../atoms/MessagesIcon/MessagesIcon";

import WebinarsIcon from "../../../assets/svg/menu-icons/Webinars.svg";
import ResourcesIcon from "../../../assets/svg/menu-icons/Resources.svg";
import StudentServicesIcon from "../../../assets/svg/menu-icons/StudentServices.svg";
import ChecklistIcon from "../../../assets/svg/menu-icons/Checklist.svg";
import CampusMapIcon from "../../../assets/svg/menu-icons/CampusMap.svg";
import EmploymentDataIcon from "../../../assets/svg/menu-icons/EmploymentData.svg";

const cx = createModuleStyleExtractor(styles);

const Header = ({
  user = {},
  selectedMenu,
  setSelectedMenu,
  selectedSubMenu,
  setSelectedSubMenu,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isProspect,
    isParent,
    selectedSchool = {},
    schools = [],
    permissions = {},
  } = useSelector((state) => state.userState);
  const selectedSchoolId = selectedSchool?.id;

  const { unreadNotificationCount, chatNotificationCount } = useSelector(
    (state) => state.notificationState
  );

  const schoolsMenuRef = useClickOutside(() => setShowSchoolsMenu(false));

  const [showSchoolsMenu, setShowSchoolsMenu] = useState(false);
  const [showNotificationsDrawer, setShowNotificationsDrawer] = useState(false);

  const MENUS = [
    {
      id: 1,
      title: "Chat",
      routes: [ROUTES.CHAT.AMBASSADORS],
      mixpanelEvent: "Chat",
      tooltip: "Chat with students, alumni, and staff members",
      permission: permissions["chat"],
    },
    {
      id: 2,
      title: "Community",
      routes: [ROUTES.COMMUNITY.TOPICS],
      mixpanelEvent: "Community",
      tooltip: "Join groups relevant to your interest",
      permission: permissions["topics"],
    },
    ...(isProspect || isParent
      ? [
          {
            id: 3,
            title: "Learn",
            routes: [
              ROUTES.DISCOVER.WEBINARS,
              ROUTES.DISCOVER.RESOURCES,
              ROUTES.DISCOVER.STUDENT_SERVICES,
              ROUTES.TOOLS.CHECKLISTS,
            ],
            tooltip: "Step-by-step guidance on your U.S. education",
            permission:
              permissions["webinar"] ||
              permissions["resources"] ||
              permissions["student_services"] ||
              permissions["checklist"],
            subMenus: [
              {
                id: 41,
                title: "Webinars",
                route: ROUTES.DISCOVER.WEBINARS,
                mixpanelEvent: "Webinars",
                permission: permissions["webinar"],
                icon: WebinarsIcon,
              },
              {
                id: 42,
                title: "Resources",
                route: ROUTES.DISCOVER.RESOURCES,
                mixpanelEvent: "Resources",
                permission: permissions["resources"],
                icon: ResourcesIcon,
              },
              {
                id: 43,
                title: "Services",
                route: ROUTES.DISCOVER.STUDENT_SERVICES,
                mixpanelEvent: "Services",
                permission: permissions["student_services"],
                icon: StudentServicesIcon,
              },
              {
                id: 44,
                title: "Checklists",
                route: ROUTES.TOOLS.CHECKLISTS,
                mixpanelEvent: "Checklists",
                permission: permissions["checklist"],
                icon: ChecklistIcon,
              },
            ],
          },
        ]
      : []),
    {
      id: 4,
      title: "Tools",
      routes: [ROUTES.CAMPUS_MAP, ROUTES.DISCOVER.EMPLOYMENT_DATA_HUB],
      tooltip: "Access career data and campus maps",
      permission:
        (permissions["employment_data"] && permissions["opt_upload"]) ||
        (permissions["campus_map"] && permissions["campus_map_data_uploaded"]),
      subMenus: [
        {
          id: 41,
          title: "Campus Map",
          route: ROUTES.CAMPUS_MAP,
          mixpanelEvent: "Campus Map",
          permission:
            permissions["campus_map"] &&
            permissions["campus_map_data_uploaded"],
          icon: CampusMapIcon,
        },
        {
          id: 42,
          title: "Career",
          route: ROUTES.DISCOVER.EMPLOYMENT_DATA_HUB,
          mixpanelEvent: "Employment",
          permission:
            permissions["employment_data"] && permissions["opt_upload"],
          icon: EmploymentDataIcon,
        },
      ],
    },
  ];

  const refNotificationDrawer = useRef();
  useClickOutsideV2(refNotificationDrawer, () =>
    setShowNotificationsDrawer(false)
  );

  // Prserve selected menu on refresh/app reload
  useEffect(() => {
    const defaultSelectedMenu = MENUS.find((menu) =>
      menu.routes.includes(window?.location?.pathname)
    );
    if (defaultSelectedMenu) {
      setSelectedMenu(defaultSelectedMenu);

      if (
        defaultSelectedMenu.subMenus &&
        defaultSelectedMenu.subMenus.length > 0
      ) {
        const defaultSelectedSubMenu = defaultSelectedMenu.subMenus.find(
          (submenu) => window?.location?.pathname?.includes(submenu.route)
        );

        if (defaultSelectedSubMenu) setSelectedSubMenu(defaultSelectedSubMenu);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Disables Background Scrolling whilst the notification drawer is open
    disableBodyScroll(showNotificationsDrawer);
  }, [showNotificationsDrawer]);

  useEffect(() => {
    if (
      selectedMenu?.subMenus?.length > 0 &&
      !location.pathname.includes(ROUTES.DASHBOARD)
    ) {
      document.body.classList.add("subnav-layout");
    } else {
      document.body.classList.remove("subnav-layout");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenu]);

  // Reset selected menus when redirected to dashboard
  const onDashboardLinkClick = () => {
    setSelectedMenu(null);
    setSelectedSubMenu(null);

    Mixpanel.track("Dashboard visited", {
      schoolID: selectedSchool.school_id,
    });
  };

  const onSubmenuClick = (submenu) => {
    Mixpanel.track(`${submenu.mixpanelEvent} visited`, {
      schoolID: selectedSchoolId,
    });
    setSelectedSubMenu(submenu);
  };

  return (
    <header role="banner" className={cx("m-header-container")}>
      <div className={cx("m-header-container__main")}>
        {isProspect || isParent ? (
          <ProspectHeaderLogos
            schoolsList={schools}
            selectedSchool={selectedSchool}
            showSchoolsMenu={showSchoolsMenu}
            onSchoolMenuClick={(school) => {
              setShowSchoolsMenu(false);
              dispatch(setSelectedSchool(school));
              setShowSchoolsMenu(!showSchoolsMenu);
              Mixpanel.track("Prospect school switched", {
                schoolID: school.id,
              });
              onDashboardLinkClick(); // Redirects to dashboard on selection
            }}
            onSelectedSchoolClick={(e) => {
              if (schools.length === 1) {
                onDashboardLinkClick(); // redirect to dashboard if only one school
              } else {
                e?.preventDefault && e.preventDefault(); // Don't redirect to dashboard on menu open
                setShowSchoolsMenu(!showSchoolsMenu);
              }
            }}
            onDashboardLinkClick={onDashboardLinkClick}
            schoolsMenuRef={schoolsMenuRef}
          />
        ) : (
          <HeaderLogos
            selectedSchool={selectedSchool}
            onClick={onDashboardLinkClick}
          />
        )}

        <nav className={cx("m-header-container__nav-menu")}>
          <ul className={cx("m-header-container__nav-menu__menu-list")}>
            {MENUS.map(
              (menu, id) =>
                menu.permission && (
                  <div key={id}>
                    <MenuItem
                      menu={menu}
                      onClick={() => {
                        setSelectedMenu(menu);
                        if (menu?.subMenus?.[0]) {
                          onSubmenuClick(menu.subMenus[0]);
                          navigate(menu.subMenus[0]?.route);
                        } else {
                          Mixpanel.track(`${menu.mixpanelEvent} visited`, {
                            schoolID: selectedSchoolId,
                          });
                          setSelectedSubMenu(null);
                        }
                      }}
                      modifier={
                        menu?.routes?.includes(window?.location?.pathname)
                          ? MenuItemModifier.ACTIVE
                          : false
                      }
                    />
                  </div>
                )
            )}
          </ul>
        </nav>
        <div className={cx("m-header-container__actions-menu")}>
          {permissions["chat"] && (
            <Tooltip
              title={"View and send messages to staff, students, and alumni."}
              enterTouchDelay={0}
            >
              <div>
                <MessagesIcon
                  active={window?.location?.pathname?.includes(
                    ROUTES?.MESSAGES
                  )}
                  badge={
                    chatNotificationCount > 0 ? chatNotificationCount : null
                  }
                />
              </div>
            </Tooltip>
          )}

          <HelpCenterIcon
            active={location.pathname.includes(ROUTES.HELP_CENTER)}
          />

          <div ref={refNotificationDrawer}>
            <NotificationIcon
              active={location.pathname.includes(ROUTES.NOTIFICATIONS)}
              badge={
                unreadNotificationCount > 0 ? unreadNotificationCount : null
              }
              onClick={() => setShowNotificationsDrawer((prev) => !prev)}
              innerRef={refNotificationDrawer}
            />
            <div
              className={cx([
                "m-notifications__sidebar",
                showNotificationsDrawer
                  ? "m-notifications__sidebar_active"
                  : "",
              ])}
            >
              <NotificationsDrawer
                setShowNotificationsDrawer={setShowNotificationsDrawer}
              />
            </div>
          </div>

          <UserProfileIcon
            modifier={
              location.pathname.includes(ROUTES.PROFILE)
                ? UserProfileIconModifier.ACTIVE
                : false
            }
            userImg={user.profile_image_url}
            first_name={user.first_name}
            last_name={user.last_name}
          />
        </div>
      </div>
      {selectedMenu?.subMenus?.length > 0 &&
        !location.pathname.includes(ROUTES.DASHBOARD) && (
          <ul className={cx("m-header-container__subheader")}>
            {selectedMenu.subMenus.map(
              (submenu, id) =>
                submenu.permission && (
                  <SubMenuItem
                    subMenu={submenu}
                    key={id}
                    onClick={() => onSubmenuClick(submenu)}
                    modifier={
                      selectedSubMenu &&
                      window?.location?.pathname?.includes(submenu?.route)
                        ? SubMenuItemModifier.ACTIVE
                        : false
                    }
                  />
                )
            )}
          </ul>
        )}
    </header>
  );
};
export default memo(Header);
